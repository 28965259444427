import "./_vendor";
import vars from "./_vars";

import "./_functions";

import "./_components";
import WaveSurfer from "../../node_modules/wavesurfer.js/dist/wavesurfer.js"; // import WaveSurfer;
import Minimap from "../../node_modules/wavesurfer.js/dist/plugins/minimap.js"; // import WaveSurfer minmap;
document.addEventListener("DOMContentLoaded", function () {
  const formControl = document.querySelector(".input-form-block .wpcf7-form-control:not(.wpcf7-submit)");
  const submitButton = document.querySelector(".wpcf7-submit");

  if (formControl) {
    formControl.addEventListener("input", () => {
      if (formControl.value.length > 0) {
        submitButton.classList.add("show");
      } else {
        submitButton.classList.remove("show");
      }
    });
  }

  var url = window.location.href;
  var hash = url.split("#")[1]; // Получаем значение после символа #

  var blocks = document.querySelectorAll("[data-bs-target]");

  for (var i = 0; i < blocks.length; i++) {
    var block = blocks[i];
    var target = block.getAttribute("data-bs-target");

    if (target && target.includes(hash)) {
      block.click();
      break; // Прерываем цикл после первого совпадения
    }
  }
  const currentLangs = document.querySelectorAll(".header .current-lang");
  if (currentLangs) {
    currentLangs.forEach((currentLang) => {
      currentLang.addEventListener("click", () => {
        currentLang.classList.toggle("show");
      });
    });
  }

  const countFilterFun = function () {
    var wpfCheckbox = document.querySelectorAll(".wpc-term-item-content-wrapperinput[type='checkbox']");
    var countFilter = document.querySelector(".count-filter");
    if (countFilter) {
      function ifChecked() {
        let wpfCheckboxChecked = document.querySelectorAll(".wpc-term-item-content-wrapper input[type='checkbox']:checked");
        wpfCheckbox.forEach((wpfCheck) => {
          if (wpfCheck.checked) {
            wpfCheck.parentNode.parentNode.classList.add("wpfLiLabel--mod");
          } else {
            wpfCheck.parentNode.parentNode.classList.remove("wpfLiLabel--mod");
          }
        });

        countFilter.innerText = `(${wpfCheckboxChecked.length})`;
      }
      window.onchange = function () {
        ifChecked();
      };
      ifChecked();
    }
    if (document.querySelector(".filter-btn")) {
      document.querySelector(".filter-btn").addEventListener("click", () => {
        document.querySelector(".filters-container .widget.widget_wpc_filters_widget").classList.toggle("active");
        document.querySelector(".filter-btn").classList.toggle("active");
      });
    }
  };
  countFilterFun();
  WaveSurferPlayer();
});
const WaveSurferPlayer = function () {
  const playLists = document.querySelectorAll(".playlist");
  var playlistButtons = document.querySelectorAll("[data-playlist]");
  playlistButtons.forEach(function (button) {
    button.addEventListener("click", function () {
      var playlistId = this.getAttribute("data-playlist");
      var accordionElement = document.getElementById(playlistId);
      if (accordionElement) {
        var audioPlayPause = accordionElement.querySelector(".audio-playPause");
        if (audioPlayPause) {
          audioPlayPause.click();
        }
      }
    });
  });
  const wavesurfers = [];
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = Math.round(seconds) % 60;
    const paddedSeconds = `0${secondsRemainder}`.slice(-2);
    return `${minutes}:${paddedSeconds}`;
  };
  var audioActive = localStorage.getItem("audioActive");
  playLists.forEach((list, indexPlayList) => {
    const audios = list.querySelectorAll(".audio:not(.no-audio)");
    audios.forEach((audio, indexAudio) => {
      const webform = audio.querySelector(".audio-webform");
      const playPause = audio.querySelector(".audio-playPause");
      const timeDuration = audio.querySelector(".timeDuration");
      const timeCurrent = audio.querySelector(".timeCurrent");
      var wavesurfer = WaveSurfer.create({
        container: webform,
        waveColor: "#bebebe",
        progressColor: "#000000",
        normalize: true,
        responsive: true,
        fillParent: true,
        cursorWidth: 0,
        height: 20,
        url: `${audio.dataset.audio}`,
      });
      wavesurfers.push(wavesurfer);
      if (timeDuration) {
        wavesurfer.on("decode", (duration) => (timeDuration.textContent = formatTime(duration)));
        wavesurfer.on("timeupdate", (currentTime) => (timeCurrent.textContent = formatTime(currentTime)));
      }
      if (playPause) {
        playPause.addEventListener("click", (e) => {
          playPause.classList.toggle("active");
          audio.classList.toggle("play");
          const audioId = audio.getAttribute("data-id-audio");
          localStorage.setItem("audioIdCashe", audioId);
          localStorage.setItem("playListActive", indexPlayList);
          localStorage.setItem("audioActive", indexAudio);
          audioActive = localStorage.getItem("audioActive");
          wavesurfers.forEach((wavesurfer, index) => {
            if (index != audioActive) {
              wavesurfer.pause();
            }
          });
          wavesurfer.playPause();
          audios.forEach((a) => {
            if (a !== audio) {
              a.classList.remove("play");
            }
          });
          if (wavesurfer.isPlaying() && !audio.classList.contains("play")) {
            wavesurfer.pause();
          }
          playPause.classList.remove("active");
          timeCurrent.classList.add("d-block");
          timeDuration.classList.add("d-none");
        });
      }
      let isaudioPlaying = false;
      wavesurfer.on("play", () => {
        const audioId = audio.getAttribute("data-id-audio");
        const audioIdCashe = localStorage.getItem("audioIdCashe");
        if (audioIdCashe == audioId && !isaudioPlaying) {
          fetchAudio(audioId);
          isaudioPlaying = true;
        } else {
          if (audioIdCashe !== audioId) {
            isaudioPlaying = false;
          }
        }
        setTimeout(() => {
          const playerActive = document.querySelector(".audio--playActive");
          const playPausePlayerActive = playerActive.querySelector(".audio-play");
          const nextAudioPlay = playerActive.querySelector(".audio-next");
          const prevAudioPlay = playerActive.querySelector(".audio-prev");
          const playerActiveClose = playerActive.querySelector(".close-button");
          playPausePlayerActive.classList.add("play");
          const playerActiveWafesurfer = playerActive.querySelector(".audio-webform");
          const minimap = Minimap.create({
            height: 30,
            container: playerActiveWafesurfer,
            waveColor: "#bebebe",
            progressColor: "#000000",
          });
          const previousMinimap = wavesurfer.getActivePlugins().find((plugin) => plugin instanceof Minimap);
          previousMinimap && previousMinimap.destroy();
          wavesurfer.registerPlugin(minimap);
          const timeDurationActive = playerActive.querySelector(".timeDuration");
          const timeCurrentActive = playerActive.querySelector(".timeCurrent");
          timeDurationActive.textContent = formatTime(wavesurfer.getDuration());
          wavesurfer.on("timeupdate", (currentTime) => {
            timeCurrentActive.textContent = formatTime(currentTime);
          });
          nextAudioPlay.addEventListener("click", (e) => {
            audioActive = Number(localStorage.getItem("audioActive"));
            playerActive.querySelector(".audio-play").classList.add("play");
            wavesurfers.forEach((wavesurfer, indexWavesurfer) => {
              wavesurfer.pause();
              if (indexWavesurfer === audioActive + 1) {
                wavesurfer.play();
                playLists.forEach((list, indexPlayList) => {
                  const audios = list.querySelectorAll(".audio:not(.no-audio)");
                  audios.forEach((audio, indexAudio) => {
                    if (indexWavesurfer === indexAudio) {
                      audio.classList.add("play");
                    } else {
                      audio.classList.remove("play");
                    }
                  });
                });
                localStorage.setItem("audioActive", indexWavesurfer);
              } else if (wavesurfers.length === audioActive + 1) {
                wavesurfer.pause();
                audioActive = Number(localStorage.setItem("audioActive", 0));
                if (indexWavesurfer === audioActive + 1) {
                  wavesurfer.play();
                  playLists.forEach((list, indexPlayList) => {
                    const audios = list.querySelectorAll(".audio:not(.no-audio)");
                    audios.forEach((audio, indexAudio) => {
                      if (indexWavesurfer === indexAudio) {
                        audio.classList.add("play");
                      } else {
                        audio.classList.remove("play");
                      }
                    });
                  });
                }
              }
            });
          });
          prevAudioPlay.addEventListener("click", (e) => {
            audioActive = Number(localStorage.getItem("audioActive"));
            playerActive.querySelector(".audio-play").classList.add("play");
            wavesurfers.forEach((wavesurfer, indexWavesurfer) => {
              wavesurfer.pause();
              if (indexWavesurfer === audioActive - 1) {
                wavesurfer.play();
                playLists.forEach((list, indexPlayList) => {
                  const audios = list.querySelectorAll(".audio:not(.no-audio)");
                  audios.forEach((audio, indexAudio) => {
                    if (indexWavesurfer === indexAudio) {
                      audio.classList.add("play");
                    } else {
                      audio.classList.remove("play");
                    }
                  });
                });
                localStorage.setItem("audioActive", indexWavesurfer);
              }
            });
          });
          playPausePlayerActive.addEventListener("click", (e) => {
            audioActive = Number(localStorage.getItem("audioActive"));
            playerActive.querySelector(".audio-play").classList.toggle("play");
            wavesurfers.forEach((wavesurfer, indexWavesurfer) => {
              playLists.forEach((list, indexPlayList) => {
                const audios = list.querySelectorAll(".audio:not(.no-audio)");
                audios.forEach((audio, indexAudio) => {
                  wavesurfer.playPause();
                  if (audio.classList.contains("play")) {
                    audio.classList.remove("play");
                  }
                });
              });
              if (indexWavesurfer === audioActive) {
                //  если аудио запустилось то добавить класс play
              }
            });
          });
          playerActiveClose.addEventListener("click", (e) => {
            playerActive.querySelector(".audio-play").classList.remove("play");
            playerActive.classList.add("d-none");
            wavesurfers.forEach((wavesurfer, indexWavesurfer) => {
              playLists.forEach((list, indexPlayList) => {
                const audios = list.querySelectorAll(".audio:not(.no-audio)");
                audios.forEach((audio, indexAudio) => {
                  audio.classList.remove("play");
                });
              });
              wavesurfer.pause();
            });
          });
        }, 3000);
      });
      audio.querySelector(".accordion-button").addEventListener("click", (e) => {
        audio.classList.toggle("active");
        if (audio.classList.contains("active")) {
          audios.forEach((audio) => {
            audio.classList.remove("active");
          });
          audio.classList.add("active");
        } else {
          audio.classList.remove("active");
        }
      });
    });
  });
  wavesurfers.forEach((wavesurfer, indexWavesurfer) => {
    wavesurfer.on("finish", function () {
      nextAudioPlay.click();
    });
  });
};

var waveSurferPlayerExecuted = false;

jQuery(document).on("select2:select", function (event) {
  var url = jQuery(this).attr("href");
  jQuery.ajax({
    url: url,
    success: function (response) {
      WaveSurferPlayer();
    },
  });
});
function addItemToFavorites(itemId) {
  // Make an AJAX request to add the item to favorites
  jQuery.ajax({
    url: "add-to-favorites.php",
    data: { itemId: itemId },
    type: "POST",
    success: function (response) {
      // Update the favorites count on the client side
      var newCount = parseInt(response);
      jQuery(".favorites-count").text(newCount);
    },
  });
}
