// import { disableScroll } from "../functions/disable-scroll";
// import { enableScroll } from "../functions/enable-scroll";

(function () {
  document.addEventListener("DOMContentLoaded", function () {
    const burger = document?.querySelector("[data-burger]");
    const menu = document?.querySelector("[data-menu]");
    const menuItems = document?.querySelectorAll("[data-menu-item]");
    const overlay = document?.querySelector("[data-menu-overlay]");
    burger?.addEventListener("click", (e) => {
      burger?.classList.toggle("burger--active");
      menu?.classList.toggle("menu--active");

      if (menu?.classList.contains("menu--active")) {
        burger?.setAttribute("aria-expanded", "true");
        burger?.setAttribute("aria-label", "Закрыть меню");
        // disableScroll();
        if (window.matchMedia("(max-width: 959px)").matches) document.body.style.overflow = "hidden";
      } else {
        burger?.setAttribute("aria-expanded", "false");
        burger?.setAttribute("aria-label", "Открыть меню");
        // enableScroll();
        if (window.matchMedia("(max-width: 959px)").matches) document.body.style.overflow = "visible";
      }
    });

    overlay?.addEventListener("click", () => {
      burger?.setAttribute("aria-expanded", "false");
      burger?.setAttribute("aria-label", "Открыть меню");
      burger.classList.remove("burger--active");
      menu.classList.remove("menu--active");
      if (window.matchMedia("(max-width: 959px)").matches) document.body.style.overflow = "visible";
      // enableScroll();
    });

    menuItems?.forEach((el) => {
      el.addEventListener("click", () => {
        burger?.setAttribute("aria-expanded", "false");
        burger?.setAttribute("aria-label", "Открыть меню");
        burger.classList.remove("burger--active");
        menu.classList.remove("menu--active");
        if (window.matchMedia("(max-width: 959px)").matches) document.body.style.overflow = "visible";
        // enableScroll();
      });
    });
  });
})();
